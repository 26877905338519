<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Le graphique ci-dessous est un graphique d'Arrhénius qui montre comment la constante de
        vitesse d'une réaction change en fonction de la température. En utilisant ces informations,
        répondez à chacune des questions ci-dessous.
      </p>

      <p>
        <span style="color: #a2a2a2">
          Note : Vous pouvez utiliser votre souris sur le graphique pour vous aider à déterminer les
          chiffres.
        </span>
      </p>

      <p class="mb-3">
        <apex-chart
          type="area"
          height="400"
          width="600"
          :options="kineticsPlotOptions"
          :series="kineticsData"
        />
      </p>

      <p class="mb-2 pl-2">
        a) Quelle est l'énergie d'activation,
        <stemble-latex content="$\text{E}_{a},$" />
        de la réaction en
        <stemble-latex content="$\text{J/mol}?$" />
      </p>

      <calculation-input
        v-model="inputs.Ea"
        class="mb-5"
        prepend-text="$\text{E}_{a}:$"
        append-text="$\text{J/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2 pl-2">
        b) Déterminez le facteur de fréquence (ou facteur pré-exponentiel)
        <stemble-latex :content="'$\\text{A},$'" />
        pour la réaction.
      </p>

      <calculation-input
        v-model="inputs.freqFactor"
        class="mb-5"
        prepend-text="$\text{A:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2 pl-2">
        c) Déterminez la valeur de la constante de vitesse,
        <stemble-latex content="$\text{k},$" />
        pour cette réaction à une température de
        <number-value :value="temp" unit="\text{K.}" />
      </p>

      <calculation-input
        v-model="inputs.rateConstant"
        prepend-text="$\text{k:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import {linspace} from '../../utils/math';
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'Question172',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
    ApexChart: VueApexCharts,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      kineticsPlotOptionsStatic: {
        chart: {
          shadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          stroke: {
            curve: 'straight',
          },
          toolbar: {
            show: false,
          },
        },
        colors: ['#307205', '#474747'],
        dataLabels: {
          enabled: false,
        },
        grid: {
          borderColor: '#c9c9c9',
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 0,
        },
        yaxis: {
          title: {
            text: 'ln(k)',
            style: {
              color: undefined,
              fontSize: '16px',
              fontFamily: 'Times New Roman',
              fontWeight: 600,
              cssClass: 'apexcharts-yaxis-title',
            },
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
            height: 6,
            style: {
              fontSize: '16px',
              fontFamily: 'Times New Roman',
              fontWeight: 600,
            },
          },
          labels: {
            formatter(val) {
              return val.toFixed(2);
            },
            style: {
              fontSize: '14px',
              fontFamily: 'Times New Roman',
              fontWeight: 600,
            },
          },
          // tickAmount: 10,
          // tickPlacement: 'between',
          // min: 0,
          // max: 10,
        },
        xaxis: {
          title: {
            text: '1/T (1/K)',
            style: {
              color: undefined,
              fontSize: '16px',
              fontFamily: 'Times New Roman',
              fontWeight: 600,
              cssClass: 'apexcharts-xaxis-title',
            },
          },
          labels: {
            formatter(val) {
              return val.toFixed(5);
            },
            style: {
              fontSize: '14px',
              fontFamily: 'Times New Roman',
              fontWeight: 600,
            },
          },
          tickAmount: 5,
          // tickPlacement: 'between',
          // min: 0,
          // max: 10,
        },
      },
      inputs: {
        Ea: null,
        freqFactor: null,
        rateConstant: null,
      },
    };
  },

  computed: {
    temp() {
      return this.taskState.getValueBySymbol('temp').content;
    },
    proxyEa() {
      return this.taskState.getValueBySymbol('proxyEa').content;
    },
    FF() {
      return this.taskState.getValueBySymbol('FF').content;
    },
    xValues() {
      return linspace(0, 0.0025, 251);
    },
    kineticsFunction() {
      // The proxy value / 50 is just so that student's can't look at the task state in the server's response
      return (T) => Math.log(this.FF.toFloat()) - (this.proxyEa.toFloat() / 50 / 8.314) * T;
    },
    kineticsData() {
      const data = this.xValues.map((T) => [T, this.kineticsFunction(T)]);
      return [
        {
          name: 'ln(k)',
          data,
        },
      ];
    },
    kineticsPlotOptions() {
      return this.kineticsPlotOptionsStatic;
    },
  },
};
</script>
